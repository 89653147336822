import React from "react";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Contact = ({ contact, onDelete }) => {
  const { setUserAsUnread, showToast, setUsers } = useUsersContext();
  const history = useHistory();

  // let parameter = useParams();
  const { pathname } = useLocation();
  const loc_id = pathname.split("/")[1];
  const loc_name = pathname.split("/")[2];
  const username = pathname.split("/")[3];
  const getLastMessage = () => {
    const messageDates = Object.keys(contact.messages);
    const recentMessageDate = messageDates[messageDates.length - 1];
    const messages = [...contact.messages[recentMessageDate]];
    const lastMessage = messages.pop();
    return lastMessage;
  };

  const handleDeleteClick = async (e, contactId) => {
    e.stopPropagation();
    e.preventDefault();
    const deleteRes = await onDelete(contactId);
    if (deleteRes.status === 200) {
      setUsers((users) => users.filter((user) => user.id !== contactId));
      const redirectUrl = `/${loc_id}/${loc_name}/${username}`;
      history.push(redirectUrl);
    } else {
      showToast('Something went wrong!')
    }
  };

  const lastMessage = getLastMessage(contact);
  return (
    <Link
      className="sidebar-contact"
      to={`/${loc_id}/${loc_name}/${username}/chat/${contact.id}`}
      onClick={() => setUserAsUnread(contact.id)}
    >
      <div className="sidebar-contact__content">
        <div className="sidebar-contact__top-content">
          <h2 className="sidebar-contact__name"> {contact.name} </h2>
          <p className="sidebar-contact__message-wrapper">
            {lastMessage?.status && (
              <Icon
                id={
                  lastMessage?.status === "sent" ? "singleTick" : "doubleTick"
                }
                aria-label={lastMessage?.status}
                className={`sidebar-contact__message-icon ${lastMessage?.status === "read"
                  ? "sidebar-contact__message-icon--blue"
                  : ""
                  }`}
              />
            )}
            <span
              className={`sidebar-contact__message ${!!contact.unread ? "sidebar-contact__message--unread" : ""
                }`}
            >
              {/* <div dangerouslySetInnerHTML={{ __html: lastMessage?.content }} /> */}
              {contact.typing ? (
                <i> typing...</i>
              ) : (
                <div
                  className="limited-lines"
                  dangerouslySetInnerHTML={{ __html: lastMessage?.content }}
                />
              )}
            </span>
          </p>
        </div>
        <div className="sidebar-contact__bottom-content">
          <div className="sidebar-contact__time">
            <span className="userChatIcon truncateUserName">
              <Icon id="user" />
              {contact.whatsapp_name}
            </span>
            <span className="deleteChatBtn" onClick={(e) => handleDeleteClick(e, contact.id)} >
              <Icon id="delete" />
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Contact;
