import React, { useState } from "react";
import "./style.css";
import Data from "data/contacts";
import { useLocation } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import { useHistory } from "react-router-dom";
import settings from "assets/images/cog.svg";
import Icon from "components/Icon";
import Tooltip from "components/Tooltip";

function ChatModal({ processing, onExplorePromots, showExploreContent }) {
  const history = useHistory();
  const location = useLocation();
  const shouldShowExploreButton = location.pathname.includes("/chat/");
  const { setUserAsUnread, refresh, showToast } = useUsersContext();
  const [isShowExploreBtn, setIsShowExploreBtn] = useState(false);
  const [isExploreActive, setIsExploreActive] = useState(false);
  const { ChatSubmit } = Data();

  const { pathname } = useLocation();
  const loc_id = pathname.split("/")[1];
  const loc_name = pathname.split("/")[2];
  const username = pathname.split("/")[3];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      locationId: loc_id,
      username: username,
    };

    const temp = await ChatSubmit(payload);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/${loc_id}/${loc_name}/${username}/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);
      setIsShowExploreBtn(true);
    } else {
      setIsShowExploreBtn(false);
      showToast("Something went wrong!");
    }
    if (isExploreActive) onExplorePromots(false);
  };

  const handleConnect = (e) => {
    e.preventDefault();
    window.location.href = `https://connect.maybetech.com/?profile_id=${loc_id}&profile_name=${loc_name}&username=${username}&type=chat`;
  };

  const handleExplorePromots = (e) => {
    e.preventDefault();
    onExplorePromots();
  };

  return (
    <div className="chat-dropdown">
      <div>
        <button
          className={`btn btn-newChat ${
            !showExploreContent ? "activeBtn" : ""
          }`}
          disabled={processing}
          aria-label="New chat"
          style={{ width: "115px" }}
          onClick={handleSubmit}
        >
          New Chat
        </button>

        {/* {shouldShowExploreButton && ( */}
        {/* <Tooltip text="Please open any chat first!" show={!shouldShowExploreButton} className={!shouldShowExploreButton ? "show-tooltip" : ""}> */}
          <button
            className={`btn btn-explore ${
              showExploreContent ? "activeBtn" : ""
            }`}
            aria-label="New chat"
            style={{ width: "115px" }}
            onClick={handleExplorePromots}
          >
            {showExploreContent ? (
              <Icon id="close" />
            ) : (
              <Icon id="exp_dots" className="exp-dots" />
            )}
            Explore
          </button>
        {/* </Tooltip> */}
        {/* )} */}
      </div>
      <span style={{ cursor: "pointer" }} onClick={(e) => handleConnect(e)}>
        <Icon id="cog" />
      </span>
    </div>
  );
}

export default ChatModal;
